<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  field: {
    type: Object,
    default: () => {}
  }
});

const errorMessage = computed(() => {
  return props.data.item?.[props.field?.errorKey] ?? "";
});
const status = computed(() => {
  return props.data.item?.[props.field?.key] ?? "";
});
const startTime = computed(() => {
  return props.data.item?.[props.field?.dateTimeStart];
});

function formatDateString(obj) {
  try {
    // Extract the timestamp
    const timestamp = parseInt(obj.$date.$numberLong, 10);

    // Create a new Date object using the timestamp
    const date = new Date(timestamp);

    // Format the date
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Construct the formatted date string
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  } catch (error) {
    return "";
  }
}
</script>

<template>
  <div class="d-flex text-no-wrap">
    <template v-if="field?.onlyDate">
      {{ formatDateString(status) }}
    </template>
    <template v-else-if="errorMessage">
      <span
        v-b-popover.hover.top="errorMessage"
        class="circle error mr-2"
      ></span>
      <span>{{ formatDateString(startTime) }}</span>
    </template>
    <template v-else-if="status.toString() === 'true'">
      <span
        v-b-popover.hover.top="
          $t('salesChannelManagementApp.journal.statusSuccess')
        "
        class="circle success mr-2"
      ></span>
      <span>{{ formatDateString(startTime) }}</span>
    </template>
    <template v-else>
      <span
        v-b-popover.hover.top="
          $t('salesChannelManagementApp.journal.statusDefault')
        "
        class="circle"
      ></span>
    </template>
  </div>
</template>

<style lang="scss">
.circle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #dfdada;

  &.success {
    background-color: lighten($color-connect-success, 10) !important;
  }

  &.error {
    background-color: lighten($color-connect-error, 12.5) !important;
  }
}
</style>
